import { createRouter, createWebHistory,RouteRecordRaw } from 'vue-router'

import consoleLayout from '@/views/console/layout/index.vue'

const routes:RouteRecordRaw[] = [
  {
    path: '/',
    redirect:'/console/user'
  },
  {
    path:'/login',
    name:'login',
    component:()=>import('@/views/login/index.vue')
  },
  {
    path:'/report/:id?',
    name:'report',
    component:()=>import('@/views/report/index.vue')
  }
]

export const consoleRoutes:RouteRecordRaw[] = [
  {
    path:'/console',
    name:'console',
    component:consoleLayout,
    children:[
      {
        path:'user',
        name:'user',
        meta:{
          title:'个人中心',
          hidden:true
        },
        component:()=>import('@/views/console/user/index.vue')
      },
      {
        path:'project',
        name:'project',
        meta:{
          title:'园区管理',
          icon:'icon-qiyeyuanquwuye',
          permission:['Project.List']
        },
        component:()=>import('@/views/console/project/index.vue'),
        children:[
          {
            path:'',
            name:'list-project',
            meta:{
              hidden:true,
              title:'园区列表',
              permission:['Project.List']
            },
            component:()=>import('@/views/console/project/list.vue')
          },{
            path:'detail',
            name:'detail-project',
            meta:{
              hidden:true,
              title:'园区详情',
              keepAlive:true,
              permission:['Project.Detail'],
              bread:[
                {title:'园区列表',url:'/console/project'},
                {title:'园区详情',url:''},
              ]
            },
            component:()=>import('@/views/console/project/detail.vue')
          },{
            path:'building',
            name:'building-project',
            meta:{
              hidden:true,
              title:'楼宇详情',
              bread:[
                {title:'园区列表',url:'/console/project'},
                {title:'项目详情',url:''},
                {title:'楼宇详情',url:''},
              ]
            },
            component:()=>import('@/views/console/project/building.vue')
          },{
            path:'addBuild',
            name:'addBuild-project',
            meta:{
              hidden:true,
              title:'添加楼宇',
              permission:['Project.Building.Add'],
              bread:[
                {title:'园区列表',url:'/console/project'},
                {title:'项目详情',url:''},
                {title:'添加楼宇',url:''},
              ]
            },
            component:()=>import('@/views/console/project/addBuild.vue')
          },{
            path:'editBuild',
            name:'editBuild-project',
            meta:{
              hidden:true,
              title:'编辑楼宇',
              permission:['Project.Building.Edit'],
              bread:[
                {title:'园区列表',url:'/console/project'},
                {title:'园区详情',url:''},
                {title:'编辑楼宇',url:''},
              ]
            },
            component:()=>import('@/views/console/project/addBuild.vue')
          },{
            path:'rules',
            name:'rules-project',
            meta:{
              hidden:true,
              title:'返佣规则',
              permission:['Project.ZhaoZu.Rule','Project.ZhaoShang.Rule'],
            },
            component:()=>import('@/views/console/project/rules/index.vue'),
            children:[
              {
                path:'add',
                name:'add-rules-project',
                meta:{
                  title:'添加返佣规则',
                  permission:['Project.ZhaoZu.Rule.Add','Project.ZhaoShang.Rule.Add'],
                  bread:[
                    {title:'园区列表',url:'/console/project'},
                    {title:'园区详情',url:''},
                    {title:'添加返佣规则',url:''},
                  ]
                },
                component:()=>import('@/views/console/project/rules/add.vue')
              },
              {
                path:'edit',
                name:'edit-rules-project',
                meta:{
                  title:'编辑返佣规则',
                  permission:['Project.ZhaoZu.Rule.Edit','Project.ZhaoShang.Rule.Edit'],
                  bread:[
                    {title:'园区列表',url:'/console/project'},
                    {title:'园区详情',url:''},
                    {title:'编辑返佣规则',url:''},
                  ]
                },
                component:()=>import('@/views/console/project/rules/add.vue')
              },
              {
                path:'preview/add',
                name:'add-preview-project',
                meta:{
                  title:'添加带看奖励',
                  permission:['Project.ZhaoZu.Rule.Add'],
                  bread:[
                    {title:'园区列表',url:'/console/project'},
                    {title:'园区详情',url:''},
                    {title:'添加带看奖励',url:''},
                  ]
                },
                component:()=>import('@/views/console/project/rules/preview/add.vue')
              },
              {
                path:'preview/edit',
                name:'edit-preview-project',
                meta:{
                  title:'编辑带看奖励',
                  permission:['Project.ZhaoZu.Rule.Add'],
                  bread:[
                    {title:'园区列表',url:'/console/project'},
                    {title:'园区详情',url:''},
                    {title:'编辑带看奖励',url:''},
                  ]
                },
                component:()=>import('@/views/console/project/rules/preview/add.vue')
              },
              {
                path:'rent',
                name:'rent-rules-project',
                meta:{
                  title:'招租规则',
                  permission:['Project.ZhaoZu.Rule'],
                  // bread:[
                  //   {title:'园区列表',url:'/console/project'},
                  //   {title:'园区详情',url:''},
                  //   {title:'规则详情',url:''},
                  // ]
                },
                component:()=>import('@/views/console/project/rules/rent/index.vue'),
                children:[
                  {
                    path:'add',
                    name:'add-rent-rules-project',
                    meta:{
                      permission:['Project.ZhaoZu.Rule.Add'],
                      bread:[
                        {title:'园区列表',url:'/console/project'},
                        {title:'园区详情',url:''},
                        {title:'添加招租规则',url:''},
                      ]
                    },
                    component:()=>import('@/views/console/project/rules/rent/add.vue')
                  },
                  {
                    path:'edit',
                    name:'edit-rent-rules-project',
                    meta:{
                      permission:['Project.ZhaoZu.Rule.Edit'],
                      bread:[
                        {title:'园区列表',url:'/console/project'},
                        {title:'园区详情',url:''},
                        {title:'编辑招租规则',url:''},
                      ]
                    },
                    component:()=>import('@/views/console/project/rules/rent/add.vue')
                  },
                  {
                    path:'detail',
                    name:'detail-rent-rules-project',
                    meta:{
                      permission:['Project.ZhaoZu.Rule.Detail'],
                      bread:[
                        {title:'园区列表',url:'/console/project'},
                        {title:'园区详情',url:''},
                        {title:'招租规则详情',url:''},
                      ]
                    },
                    component:()=>import('@/views/console/project/rules/rent/detail.vue')
                  }
                ]
              },
              {
                path:'investment',
                name:'investment-rules-project',
                meta:{
                  title:'规则详情',
                  permission:['Project.ZhaoShang.Rule.Detail'],
                  bread:[
                    {title:'园区列表',url:'/console/project'},
                    {title:'园区详情',url:''},
                    {title:'规则详情',url:''},
                  ]
                },
                component:()=>import('@/views/console/project/rules/investment/detail.vue')
              }
            ]
          }
        ]
      },
      {
        path:'items',
        name:'items',
        meta:{
          title:'项目管理',
          icon:'icon-project',
          permission:['Project.List'],
          hidden:true
        },
        component:()=>import('@/views/console/items/index.vue'),
        children:[
          {
            path:'',
            name:'list-item',
            meta:{
              title:'列表',
              hidden:true,
              keepAlive:true
            },
            component:()=>import('@/views/console/items/list.vue')
          }
        ]
      },
      {
        path:'building',
        name:'building',
        meta:{
          title:'楼宇管理',
          icon:'icon-yuanquyunwei',
          permission:['Project.List']
        },
        component:()=>import('@/views/console/building/index.vue'),
        children:[
          {
            path:'',
            name:'list-building',
            meta:{
              title:'列表',
              hidden:true,
              keepAlive:true
            },
            component:()=>import('@/views/console/building/list.vue')
          },
          {
            path:'add',
            name:'add-building',
            meta:{
              hidden:true,
              title:'添加楼宇',
              permission:['Project.Building.Add'],
              bread:[
                {title:'楼宇列表',url:'/console/building'},
                {title:'添加楼宇',url:''},
              ]
            },
            component:()=>import('@/views/console/project/addBuild.vue')
          },
          {
            path:'edit',
            name:'edit-building',
            meta:{
              hidden:true,
              title:'编辑楼宇',
              permission:['Project.Building.Edit'],
              bread:[
                {title:'楼宇列表',url:'/console/building'},
                {title:'编辑楼宇',url:''},
              ]
            },
            component:()=>import('@/views/console/project/addBuild.vue')
          },
          {
            path:'detail',
            name:'detail-building',
            meta:{
              hidden:true,
              title:'楼宇详情',
              bread:[
                {title:'楼宇列表',url:'/console/building'},
                {title:'楼宇详情',url:''},
              ]
            },
            component:()=>import('@/views/console/building/detail.vue')
          }
        ]
      },
      {
        path:'reserveItem',
        name:'reserveItem',
        meta:{
          title:'储备项目库',
          icon:'icon-chubeixiangmu',
          hidden:true
        },
        component:()=>import('@/views/console/reserveItem/index.vue'),
        children:[
          {
            path:'',
            name:'list-reserveItem',
            meta:{
              title:'列表',
              keepAlive:true,
              hidden:true
            },
            component:()=>import('@/views/console/reserveItem/list.vue')
          },
          {
            path:'add',
            name:'add-reserveItem',
            meta:{
              title:'新增储备项目',
              hidden:true,
              bread:[
                {title:'储备项目库',url:'/console/reserveItem'},
                {title:'新增储备项目',url:''}
              ]
            },
            component:()=>import('@/views/console/reserveItem/add.vue')
          },
          {
            path:'edit',
            name:'edit-reserveItem',
            meta:{
              title:'新增储备项目',
              hidden:true,
              bread:[
                {title:'储备项目库',url:'/console/reserveItem'},
                {title:'编辑储备项目',url:''}
              ]
            },
            component:()=>import('@/views/console/reserveItem/add.vue')
          },
          {
            path:'detail',
            name:'detail-reserveItem',
            meta:{
              title:'项目详情',
              hidden:true,
              bread:[
                {title:'储备项目库',url:'/console/reserveItem'},
                {title:'项目详情',url:''}
              ]
            },
            component:()=>import('@/views/console/reserveItem/detail.vue')
          }
        ]
      },
      {
        path:'order',
        name:'order',
        meta:{
          title:'订单管理',
          icon:'icon-order',
          permission:['Order.Management'],
          hidden:true
        },
        component:()=>import('@/views/console/order/index.vue'),
        children:[
          {
            path:'mine',
            name:'mine-order',
            meta:{
              title:'订单列表',
              permission:['Order.Management.Renting.List']
            },
            component:()=>import('@/views/console/order/mine/index.vue'),
            children:[
              {
                path:'',
                name:'list-mine-order',
                meta:{
                  title:'列表',
                  keepAlive:true,
                  hidden:true,
                },
                component:()=>import('@/views/console/order/mine/list.vue')
              },
              {
                path:'detail/:type?',
                name:'detail-mine-order',
                meta:{
                  title:'订单详情',
                  hidden:true,
                  bread:[
                    {title:'订单列表',url:'/console/order/mine'},
                    {title:'订单详情',url:''}
                  ]
                },
                component:()=>import('@/views/console/order/mine/detail.vue')
              }
            ]
          },
          {
            path:'audit',
            name:'audit-order',
            meta:{
              title:'订单审核',
              permission:['Order.Management.Renting.Check']
            },
            component:()=>import('@/views/console/order/audit/index.vue'),
            children:[
              {
                path:'',
                name:'list-audit-order',
                meta:{
                  title:'审核列表',
                  keepAlive:true,
                  hidden:true,
                },
                component:()=>import('@/views/console/order/audit/list.vue')
              }
            ]
          },
          {
            path:'remit',
            name:'remit-order',
            meta:{
              title:'打款',
              permission:['Order.Management.Renting.Pay']
            },
            component:()=>import('@/views/console/order/remit/index.vue'),
            children:[
              {
                path:'',
                name:'list-remit-order',
                meta:{
                  title:'打款列表',
                  keepAlive:true,
                  hidden:true,
                },
                component:()=>import('@/views/console/order/remit/list.vue')
              },
              {
                path:'detail/:type?',
                name:'detail-remit-order',
                meta:{
                  title:'打款详情',
                  hidden:true,
                  bread:[
                    {title:'打款列表',url:'/console/order/remit'},
                    {title:'打款详情',url:''}
                  ]
                },
                component:()=>import('@/views/console/order/remit/detail.vue')
              }
            ]
          }
        ]
      },
      {
        path:'recruit',
        name:'recruit',
        meta:{
          title:'发布招募',
          icon:'icon-fabuzhaomu',
          hidden:true
          // permission:['ZhaoZuRecruit.ZhaoMU','ZhaoShangRecruit.ZhaoMU']
        },
        component:()=>import('@/views/console/recruit/index.vue'),
        children:[
          {
            path:'',
            name:'list-recruit',
            meta:{
              title:'列表',
              keepAlive:true,
              hidden:true,
            },
            component:()=>import('@/views/console/recruit/list.vue')
          },
          {
            path:'detail/:type?',
            name:'detail-recruit',
            meta:{
              title:'z',
              hidden:true,
              bread:[
                {title:'发布招募',url:''},
                {title:'招募详情',url:''},
              ]
            },
            component:()=>import('@/views/console/recruit/detail.vue')
          },
          {
            path:'rule/:type?',
            name:'rule-recruit',
            meta:{
              title:'规则详情',
              hidden:true,
              bread:[
                {title:'发布招募',url:''},
                {title:'规则详情',url:''},
              ]
            },
            component:()=>import('@/views/console/recruit/rule.vue')
          }
        ]
      },
      {
        path:'myIntermediary',
        name:'MyIntermediary',
        meta:{
          title:'我的中介',
          icon:'icon-zhongjie',
          hidden:true
        },
        component:()=>import('@/views/console/myIntermediary/index.vue'),
        children:[
          {
            path:'',
            name:'list-MyIntermediary',
            meta:{
              title:'列表',
              hidden:true
            },
            component:()=>import('@/views/console/myIntermediary/list.vue')
          },
          {
            path:'person',
            name:'person-MyIntermediary',
            meta:{
              title:'中介详情',
              hidden:true,
              keepAlive:true,
              bread:[
                {title:'我的中介',url:'/console/myIntermediary'},
                {title:'中介详情',url:''}
              ]
            },
            component:()=>import('@/views/console/myIntermediary/person.vue')
          },
          {
            path:'order/:type?',
            name:'order-MyIntermediary',
            meta:{
              title:'订单详情',
              hidden:true,
              bread:[
                {title:'我的中介',url:'/console/myIntermediary'},
                {title:'中介详情',url:''},
                {title:'订单详情',url:''},
              ]
            },
            component:()=>import('@/views/console/myIntermediary/order.vue')
          }
        ]
      },
      {
        path:'member',
        name:'member',
        meta:{
          title:'成员管理',
          icon:'icon-zuzhijiagou',
          permission:['AbpIdentity.Users']
        },
        component:()=>import('@/views/console/member/index.vue'),
        children:[
          {
            path:'',
            name:'main-member',
            meta:{
              hidden:true,
            },
            component:()=>import('@/views/console/member/index.vue')
          },
          {
            path:'people',
            name:'people-member',
            meta:{
              hidden:true,
              title:'成员列表',
              permission:['AbpIdentity.Users.ManagePermissions']
            },
            component:()=>import('@/views/console/member/people/index.vue'),
            children:[
              {
                path:'',
                name:'list-people-member',
                meta:{
                  title:'成员列表',
                  keepAlive:true
                },
                component:()=>import('@/views/console/member/people/list.vue'),
              },
              {
                path:'add',
                name:'add-people-member',
                meta:{
                  title:'添加成员',
                  hidden:true,
                  permission:['AbpIdentity.Users.Create'],
                  bread:[
                    {title:'成员列表',url:'/console/member/people'},
                    {title:'添加成员',url:''}
                  ]
                },
                component:()=>import('@/views/console/member/people/add.vue')
              },
              {
                path:'edit',
                name:'edit-people-member',
                meta:{
                  title:'编辑成员',
                  hidden:true,
                  permission:['AbpIdentity.Users.Update'],
                  bread:[
                    {title:'成员列表',url:'/console/member/people'},
                    {title:'编辑成员',url:''}
                  ]
                },
                component:()=>import('@/views/console/member/people/add.vue')
              },
              {
                path:'detail',
                name:'detail-people-member',
                meta:{
                  title:'成员详情',
                  hidden:true,
                  bread:[
                    {title:'成员列表',url:'/console/member/people'},
                    {title:'成员详情',url:''}
                  ]
                },
                component:()=>import('@/views/console/member/people/detail.vue')
              }
            ]
          },
          {
            path:'section',
            name:'section-member',
            meta:{
              hidden:true,
              title:'部门列表',
              permission:['Department.Management']
            },
            component:()=>import('@/views/console/member/section/index.vue'),
            children:[
              {
                path:'',
                name:'list-section-member',
                meta:{
                  keepAlive:true,
                  title:'部门列表',
                },
                component:()=>import('@/views/console/member/section/list.vue'),
              }
            ]
          },
          {
            path:'role',
            name:'role-member',
            meta:{
              hidden:true,
              title:'角色列表',
              permission:['AbpIdentity.Roles.ManagePermissions']
            },
            component:()=>import('@/views/console/member/role/index.vue'),
            children:[
              {
                path:'',
                name:'list-role-member',
                meta:{
                  title:'角色列表',
                  keepAlive:true
                },
                component:()=>import('@/views/console/member/role/list.vue'),
              },
              {
                path:'add',
                name:'add-role-member',
                meta:{
                  title:'添加角色',
                  hidden:true,
                  permission:['AbpIdentity.Roles.Create'],
                  bread:[
                    {title:'角色列表',url:'/console/member/role'},
                    {title:'添加角色',url:''}
                  ]
                },
                component:()=>import('@/views/console/member/role/add.vue'),
              },
              {
                path:'edit',
                name:'edit-role-member',
                meta:{
                  title:'编辑角色',
                  hidden:true,
                  permission:['AbpIdentity.Roles.Update'],
                  bread:[
                    {title:'角色列表',url:'/console/member/role'},
                    {title:'添加角色',url:''}
                  ]
                },
                component:()=>import('@/views/console/member/role/add.vue'),
              },
              {
                path:'detail',
                name:'detail-role-member',
                meta:{
                  title:'角色详情',
                  hidden:true,
                  permission:['AbpIdentity.Roles.Check'],
                  bread:[
                    {title:'角色列表',url:'/console/member/role'},
                    {title:'角色详情',url:''}
                  ]
                },
                component:()=>import('@/views/console/member/role/detail.vue'),
              }
            ]
          }
        ]
      },
      {
        path:'commission',
        name:'Commission',
        meta:{
          title:'佣金统计',
          icon:'icon-yongjinguanli',
          permission:['CommissionStatistics.List'],
          hidden:true
        },
        component:()=>import('@/views/console/commission/index.vue'),
        children:[
          {
            path:'',
            name:'list-Commission',
            meta:{
              title:'佣金统计',
              hidden:true
            },
            component:()=>import('@/views/console/commission/list.vue')
          }
        ]
      },
      {
        path:'accountRole',
        name:'AccountRole',
        meta:{
          title:'账号与角色',
          icon:'icon-jiaoseguanli',
          permission:['AbpIdentity.Users'],
          hidden:true
        },
        component:()=>import('@/views/console/accountRole/index.vue'),
        children:[
          {
            path:'account',
            name:'account-AccountRole',
            meta:{
              title:'账号列表',
              permission:['AbpIdentity.Users.ManagePermissions']
            },
            component:()=>import('@/views/console/accountRole/account/index.vue'),
            children:[
              {
                path:'',
                name:'list-account-AccountRole',
                meta:{
                  title:'账号列表',
                  keepAlive:true,
                  hidden:true
                },
                component:()=>import('@/views/console/accountRole/account/list.vue')
              },
              {
                path:'add',
                name:'add-account-AccountRole',
                meta:{
                  title:'添加账号',
                  hidden:true,
                  permission:['AbpIdentity.Users.Create'],
                  bread:[
                    {title:'账号列表',url:'/console/accountRole/account'},
                    {title:'添加账号',url:''}
                  ]
                },
                component:()=>import('@/views/console/accountRole/account/add.vue')
              },
              {
                path:'edit',
                name:'edit-account-AccountRole',
                meta:{
                  title:'编辑账号',
                  hidden:true,
                  permission:['AbpIdentity.Users.Update'],
                  bread:[
                    {title:'账号列表',url:'/console/accountRole/account'},
                    {title:'编辑账号',url:''}
                  ]
                },
                component:()=>import('@/views/console/accountRole/account/add.vue')
              },
              {
                path:'detail',
                name:'detail-account-AccountRole',
                meta:{
                  title:'账号详情',
                  hidden:true,
                  permission:['AbpIdentity.Users.ManagePermissions'],
                  bread:[
                    {title:'账号列表',url:'/console/accountRole/account'},
                    {title:'账号详情',url:''}
                  ]
                },
                component:()=>import('@/views/console/accountRole/account/detail.vue')
              }
            ]
          },
          {
            path:'role',
            name:'role-AccountRole',
            meta:{
              title:'角色列表',
              permission:['AbpIdentity.Roles.ManagePermissions']
            },
            component:()=>import('@/views/console/accountRole/role/index.vue'),
            children:[
              {
                path:'',
                name:'list-role-AccountRole',
                meta:{
                  title:'角色列表',
                  keepAlive:true,
                  hidden:true
                },
                component:()=>import('@/views/console/accountRole/role/list.vue')
              },
              {
                path:'add',
                name:'add-role-AccountRole',
                meta:{
                  title:'添加角色',
                  hidden:true,
                  permission:['AbpIdentity.Roles.Create'],
                  bread:[
                    {title:'角色列表',url:'/console/accountRole/role'},
                    {title:'添加角色',url:''}
                  ]
                },
                component:()=>import('@/views/console/accountRole/role/add.vue')
              },
              {
                path:'edit',
                name:'edit-role-AccountRole',
                meta:{
                  title:'角色列表',
                  hidden:true,
                  permission:['AbpIdentity.Roles.Update'],
                  bread:[
                    {title:'角色列表',url:'/console/accountRole/role'},
                    {title:'编辑角色',url:''}
                  ]
                },
                component:()=>import('@/views/console/accountRole/role/add.vue')
              },
              {
                path:'detail',
                name:'detail-role-AccountRole',
                meta:{
                  title:'角色详情',
                  hidden:true,
                  permission:['AbpIdentity.Roles.ManagePermissions'],
                  bread:[
                    {title:'角色列表',url:'/console/accountRole/role'},
                    {title:'角色详情',url:''}
                  ]
                },
                component:()=>import('@/views/console/accountRole/role/detail.vue')
              }
            ]
          },
        ]
      },
      {
        path:'intermediary',
        name:'Intermediary',
        meta:{
          title:'中介管理',
          icon:'icon-zhongjiejigouguanli',
          hidden:true
        },
        component:()=>import('@/views/console/intermediary/index.vue'),
        children:[
          {
            path:'IdAudit',
            name:'IdAudit-Intermediary',
            meta:{
              title:'身份审核'
            },
            component:()=>import('@/views/console/intermediary/IdAudit.vue')
          },
          {
            path:'bindManage',
            name:'BindManage-Intermediary',
            meta:{
              title:'绑定管理'
            },
            component:()=>import('@/views/console/intermediary/bindManage.vue')
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes:routes,
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['report', 'login', 'default']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export default router
